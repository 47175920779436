import { PageContext } from "../context"
import { truncateAddress, safeNavigate, generateUrl, getParams } from "../utils"

import { Link, useNavigate, useParams } from "react-router-dom"

import { gsap } from "gsap";

import config from "../config"
import api from "../fetch";

import { useState, useRef, useContext, useEffect } from 'react'


const TOPICS = {
  GAMEPLAY: 'gameplay',
  ECONOMY: 'economy',
  DEVELOPMENT: 'development',
  PUNISHMENTS: 'punishments',
  WEBSITE: 'website',
  OTHERS: 'others'
}

const Compoment = (props) => {

    const currentAccount = props.currentAccount

    const el = useRef();
    const q = gsap.utils.selector(el)
    const modalAnim = useRef()
    const errorTitleAnim = useRef()
    const errorDescrAnim = useRef()

    const navigate = useNavigate()

    const yourAvatar = props.yourAvatar

    const [title, setTitle] = useState("")
    const [subject, setSubject] = useState("")
    const [valueSelect, setValueSelect] = useState("general")
    const [valueSelect2, setValueSelect2] = useState("gameplay")
    const [isAnim, setIsAnim] = useState(false)
    const [isAnimErrorTitle, setIsAnimErrorTitle] = useState(false)
    const [isAnimErrorDescr, setIsAnimErrorDescr] = useState(false)

    useEffect(() => {
      modalAnim.current = gsap.timeline({paused: false}).fromTo(q('.cr-form__sure-vote'), {
        opacity: 0,
        display: 'none',
      }, {
        opacity: 1,
        display: 'flex',
        duration: 0.5
      })
    }, [])

    useEffect(() => {
      errorTitleAnim.current = gsap.timeline({paused: false}).fromTo(q('.cr-form__input-title'), {
        borderBottom: '1px solid #fff',
      }, {
        borderBottom: '1px solid red',
        border: '1px solid red',
        duration: 0.1
      })
    }, [])

    useEffect(() => {
      errorDescrAnim.current = gsap.timeline({paused: false}).fromTo(q('.cr-form__input-theme'), {
        borderBottom: '1px solid #fff',
      }, {
        borderBottom: '1px solid red',
        border: '1px solid red',
        duration: 0.1
      })
    }, [])

    useEffect(() => {
      if (isAnimErrorDescr) {
        errorDescrAnim.current.play()
      } else {
        errorDescrAnim.current.reverse()
      }
    }, [isAnimErrorDescr])

    useEffect(() => {
      if (isAnimErrorTitle) {
        errorTitleAnim.current.play()
      } else {
        errorTitleAnim.current.reverse()
      }
    }, [isAnimErrorTitle])

    useEffect(() => {
      if (isAnim) {
        modalAnim.current.play()
      } else {
        modalAnim.current.reverse()
      }
    }, [isAnim])

    return (
      <div className="cr-vote__container">
        <Link to="/dao/voting/all" className="cr-vote__back title">
          <span className="cr-vote__back-arrow title__arrow"/>
          <p className="cr-vote__back-descr title__descr">
            All votes
          </p>
        </Link>
        <div className="cr-vote__top">
          <div className="cr-vote__infos">
            <h2 className="cr-vote__title">
              New vote
            </h2>
            <p className="cr-vote__date cr-vote__date2">
              {new Date().toLocaleDateString()}
            </p>
          </div>
          <div className="cr-vote__player">
            <p className="cr-vote__creator">
              Creator:
            </p>
            <div className="cr-vote__icon" style={{
              backgroundImage: `${yourAvatar}`
            }}></div>
            <p className="cr-vote__address">
              {truncateAddress(currentAccount)}
            </p>
          </div>
          <p className="cr-vote__date cr-vote__date1">
            {new Date().toLocaleDateString()}
          </p>
        </div>
        <div className="cr-vote__bottom">
          <form ref={el} action={"#"} className="cr-vote__form cr-form" onSubmit={
            async (e) => {
                e.preventDefault()
                if (title.trim() !== '' && subject.trim() !== '' && title.trim().length >= 3 && subject.trim().length >= 3) {
                  try {
                    await api.post(`/voting/create?from=${currentAccount}`, {
                      topic: valueSelect2,
                      title: title.trim(),
                      description: subject.trim(),
                    }).then(async res => {
                      setIsAnim(true)
                      await props.updateVotes()
                    })
                  } catch(err) {
                    console.log(err)
                  }
              } else {
                  if (title.trim() === '' || title.trim().length < 3) {
                    setIsAnimErrorTitle(true)
                  }
                  if (subject.trim() === '' || subject.trim().length < 3) {
                    setIsAnimErrorDescr(true)
                  }
              }
            }
          }>
            <h3 className="cr-form__title">
              Choose the topic
            </h3>
            <select name="select2" defaultValue={valueSelect2} className="cr-form__input cr-form__select" disabled={valueSelect === 'clan' ? true : false} onChange={(e) => {
              setValueSelect2(e.target.value)
            }} style={{
              cursor: valueSelect === 'clan' ? 'not-allowed' : 'auto'
            }}>
              <option className="cr-form__option" value={TOPICS.GAMEPLAY}>Gameplay</option>
              <option className="cr-form__option" value={TOPICS.ECONOMY}>Economy</option>
              <option className="cr-form__option" value={TOPICS.DEVELOPMENT}>Development</option>
              <option className="cr-form__option" value={TOPICS.PUNISHMENTS}>Punishments</option>
              <option className="cr-form__option" value={TOPICS.WEBSITE}>Website</option>
              <option className="cr-form__option" value={TOPICS.OTHERS}>Others</option>
            </select>
            <h3 className="cr-form__title">
              Title
            </h3>
            <input 
              maxLength={60} 
              onChange={(e) => {
                const value = e.target.value.replace(/[^A-Za-z\s.!?,;:]/g, '');
                setTitle((oldValue) => {
                  console.log(value)
                  if (value === '') {
                    return '';
                  } else {
                    return value;
                  }
                })
                setIsAnimErrorTitle(false);
              }} 
              value={title}
              type="text" 
              className="cr-form__input-title cr-form__input" 
              placeholder="Invitation"
            />
            <h3 className="cr-form__theme">
              Description
            </h3>
            <input 
              onChange={(e) => {
                const value = e.target.value.replace(/[^A-Za-z\s.!?,;:]/g, '');
                setSubject((oldValue) => {
                  console.log(value)
                  if (value === '') {
                    return '';
                  } else {
                    return value;
                  }
                })
                setIsAnimErrorDescr(false);
              }} 
              value={subject}
              maxLength={255} 
              type="text" 
              className="cr-form__input-theme cr-form__input" placeholder="Should we invite @raiderbeast into the clan?"
            />
            <div className="cr-form__subbottom">
              <button className="cr-form__btn" onClick={() => {
              }}>
                Create
              </button>
            </div>
            <div className="cr-form__sure cr-form__sure-vote" onClick={() => {
              setIsAnim(false)
            }}>
              <div className="sure__container" onClick={(e) => {
                e.stopPropagation()
              }}>
                <h3 className="sure__title">
                  Almost there! 
                </h3>
                <p className="sure__descr cr-sure__descr">
                Now the poll is visible only to you. To make it visible to everyone, cast your vote (for or against).<br/><br/> And let's discuss it on Discord! A new thread will be created shortly. <br/><a href="https://discord.gg/rogw" className="sure__link" rel="noreferrer noopener" target='_blank' >https://discord.gg/rogw</a>
                </p>
                <button className="sure__btn cr-sure__btn" onClick={(e) => {
                    e.preventDefault()
                    setIsAnim(false)
                    navigate('/dao/voting/all')
                  }}>
                  Ok
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    )
}

export default Compoment;